import * as types from "./ValidateiSmartTypes";

//Replace action name and update action types
export const actionRequestValidateToken = (data) => {
  return {
    type: types.POST_REQUEST_VALIDATE_TOKEN,
    data
  };
};

export const actionReceiveValidateToken = (data) => {
  return {
    type: types.POST_RECEIVE_VALIDATE_TOKEN,
    data
  };
};
