/* eslint-disable import/prefer-default-export */
/* eslint-disable import/no-unresolved */

import { AddPolicyDetail, PolicyDetail } from "@redux-state/common/PolicyDetails/PolicyDetailSagas";

import { AdminAgency } from "@redux-state/admin/AdminAgency/AdminAgencySagas";
import { AdminAgentCreate } from "@redux-state/admin/AgentCreate/AgentCreateSagas";
import { AdminAgentSearch } from "@redux-state/admin/AgentSearch/AgentSearchSagas";
import { AdminInfo } from "@redux-state/admin/AdminInfo/AdminInfoSagas";
import { AdminInfoValidate } from "@redux-state/admin/AdminInfoForValidate/AdminInfoForValidateSagas";
import { Agency } from "@redux-state/common/Agency/AgencySagas";
import { AgentDetails } from "@redux-state/admin/AgentDetailsTnC/AgentDetailsSagas";
import { AgentInfo } from "@redux-state/common/AgentInfo/AgencySagas";
import { ArchiveCoaching } from "@redux-state/Archive/ArchiveCoaching/ArchiveCoachingSagas";
import { ArchiveCoachingExport } from "@redux-state/Archive/ArchiveCoaching/ArchiveCoachingExport/ArchiveCoachingExportSagas";
import { ArchiveCoachingGetById } from "@redux-state/Archive/ArchiveCoaching/ArchiveCoachingGetById/ArchiveCoachingGetByIdSagas";
import { ArchiveJFV } from "@redux-state/Archive/ArchiveJFV/ArchiveJFVSagas";
import { ArchiveJFVDownloadDoc } from "@redux-state/Archive/ArchiveJFV/ArchiveJFVDownloadDoc/ArchiveJFVDownloadDocSagas";
import { ArchiveJFVExport } from "@redux-state/Archive/ArchiveJFV/ArchiveJFVExport/ArchiveJFVExportSagas";
import { ArchiveJfvGetById } from "@redux-state/Archive/ArchiveJFV/ArchiveJFVGetById/ArchiveJFVGetByIdSagas";
import { ArchiveMeeting } from "@redux-state/Archive/ArchiveMeeting/ArchiveMeetingSagas";
import { ArchiveMeetingExport } from "@redux-state/Archive/ArchiveMeeting/ArchiveMeetingExport/ArchiveMeetingExportSagas";
import { ArchiveMeetingGetById } from "@redux-state/Archive/ArchiveMeeting/ArchiveMeetingGetById/ArchiveMeetingGetByIdSagas";
import { AssignmentList } from "@redux-state/common/E-acknowledment/AssignmentListSagas";
import { RecordAcknowledgementList } from "@redux-state/common/RecordAcknowledgement/RecordAcknowledgementListSagas";
import { AuthToken } from "@redux-state/Auth/AuthSagas";
import { CoachingDownloadDoc } from "@redux-state/Coaching/CoachingDownloadDoc/CoachingDownloadDocSagas";
import { CoachingDownloadDocForm } from "@redux-state/Coaching/CoachingDownloadDocForm/CoachingDownloadDocFormSagas";
import { CoachingGetById } from "@redux-state/Coaching/CoachingGetById/CoachingGetByIdSagas";
import { CreateCoaching } from "@redux-state/Coaching/CreateCoaching/CreateCoachingSagas";
import { CreateJFV } from "@redux-state/JFV/JFVElectronicForm/CreateJFVSagas";
import { CreateMeeting } from "@redux-state/Meeting/MeetingElectronicForm/CreateMeetingSagas";
import { DownloadMeetingAbsent } from "@redux-state/Meeting/MeetingAbsentDownload/MeetingAbsentDownloadSagas";
import { ArchiveAttachment } from "@redux-state/JFV/JFVGetArchiveAttachment/JFVGetArchiveAttachmentSagas";
import { GetMenu } from "@redux-state/Menu/MenuSagas";
import { GetNotification } from "@redux-state/common/Notification/NotificationSagas";
import { GetAssignmentFrom } from "@redux-state/common/getAssignmentFrom/GetAssignmentFromSagas";
import { GetAssignmentTo } from "@redux-state/common/getAssignmentTo/GetAssignmentToSagas";
import { JFVGetById } from "@redux-state/JFV/JFVGetById/JFVGetByIdSagas";
import { JfvDownloadDocForm } from "@redux-state/JFV/JFVDownloadDocForm/JFVDownloadDocFormSagas";
import { LeaderAssignment } from "@redux-state/common/LeaderAssignment/LeaderAssignmentSagas";
import { LeaderJFVOverview } from "@redux-state/LeaderOverview/JFVOverview/JFVOverviewSagas";
import { LeaderList } from "@redux-state/common/LeaderList/LeaderListSagas";
import { ManageCoaching } from "@redux-state/Coaching/ManageCoaching/ManageCoachingSagas";
import { ManageJFV } from "@redux-state/JFV/ManageJFV/ManageJFVSagas";
import { ManageMeeting } from "@redux-state/Meeting/ManageMeeting/ManageMeetingSagas";
import { MeetingDownloadDoc } from "@redux-state/Meeting/MeetingDownloadDoc/MeetingDownloadDocSagas";
import { MeetingDownloadDocForm } from "@redux-state/Meeting/MeetingDownloadDocForm/MeetingDownloadDocFormSagas";
import { MeetingGetAbsentUpload } from "@redux-state/Meeting/MeetingGetAbsentUpload/MeetingGetAbsentUploadSagas";
import { MeetingGetById } from "@redux-state/Meeting/MeetingGetById/MeetingGetByIdSagas";
import { MeetingGetParticipantLists } from "@redux-state/Meeting/MeetingGetParticipantLists/MeetingGetParticipantListSagas";
import { RefreshToken } from "@redux-state/RefreshAuth/RefreshSagas";
import { SubmitAssignment } from "@redux-state/common/SubmitAssignment/SubmitAssignmentSagas";
import { SubmitRecordAcknowledgement } from "@redux-state/common/SubmitRecordAcknowledgement/SubmitRecordAcknowledgementSagas";
import { UpdateCoaching } from "@redux-state/Coaching/CoachingUpdate/CoachingUpdateSagas";
import { UpdateJFV } from "@redux-state/JFV/JFVUpdate/JFVUpdateSagas";
import { UpdateMeeting } from "@redux-state/Meeting/MeetingUpdate/MeetingUpdateSagas";
import { UploadAbsentMeeting } from "@redux-state/Meeting/MeetingAbsentUpload/MeetingAbsentUploadSagas";
import { UploadCoaching } from "@redux-state/Coaching/CoachingUpload/CoachingUploadSagas";
import { UploadCoachingForm } from "@redux-state/Coaching/CoachingUploadForm/CoachingUploadFormSagas";
import { UploadMeeting } from "@redux-state/Meeting/MeetingUpload/MeetingUploadSagas";
import { UploadMeetingForm } from "@redux-state/Meeting/MeetingUploadForm/MeetingUploadFormSagas";
import { UploadOwnJFV } from "@redux-state/JFV/OwnJFVUpload/OwnJFVUploadSagas";
import { ValidatePolicy } from "@redux-state/common/ValidatePolicy/ValidatePolicySagas";
import { ValidateAddPolicy } from "@redux-state/common/ValidatePolicy/ValidatePolicySagas";
import { ValidateTiming } from "@redux-state/common/ValidateTiming/ValidateTimingSagas";
import { ValidateToken } from "@redux-state/Validate/ValidateSagas";
import {ValidateiSmartToken} from "@redux-state/Validate_iSmart/ValidateiSmartSaga";
import { all } from "redux-saga/effects";
import { DeleteCoaching } from "@redux-state/Coaching/CoachingDelete/CoachingDeleteSagas";
import { DeleteMeeting} from "@redux-state/Meeting/MeetingDelete/MeetingDeleteSagas";
import { DeleteJFV, deleteJFV} from "@redux-state/JFV/JFVDelete/JFVDeleteSagas";


export function* watchSagas() {
  yield all([
    // AUTH
    AuthToken(),
    ValidateToken(),
    ValidateiSmartToken(),
    RefreshToken(),
    GetMenu(),

    // COMMON
    Agency(),
    AgentInfo(),
    LeaderList(),
    AssignmentList(),
    RecordAcknowledgementList(),
    SubmitAssignment(),
    SubmitRecordAcknowledgement(),
    ValidateTiming(),
    PolicyDetail(),
    AddPolicyDetail(),
    LeaderAssignment(),
    GetNotification(),
    GetAssignmentFrom(),
    GetAssignmentTo(),
    ValidatePolicy(),
    ValidateAddPolicy(),
    ArchiveAttachment(),

    // FSC
    // FSC - COACHING
    CreateCoaching(),
    ManageCoaching(),
    UploadCoaching(),
    UploadCoachingForm(),
    UpdateCoaching(),
    DeleteCoaching(),
    CoachingGetById(),
    CoachingDownloadDoc(),
    CoachingDownloadDocForm(),

    // FSC - JOINT FIELD VISIT
    CreateJFV(),
    ManageJFV(),
    UpdateJFV(),
    JFVGetById(),
    DeleteJFV(),

    // FSC - MEETING
    CreateMeeting(),
    ManageMeeting(),
    UpdateMeeting(),
    UploadMeeting(),
    UploadMeetingForm(),
    UploadAbsentMeeting(),
    MeetingGetById(),
    MeetingGetAbsentUpload(),
    DownloadMeetingAbsent(),
    MeetingDownloadDoc(),
    MeetingDownloadDocForm(),
    MeetingGetParticipantLists(),
    DeleteMeeting(),

    // FSC - ARCHIVE
    ArchiveCoaching(),
    ArchiveCoachingGetById(),
    ArchiveJFV(),
    ArchiveJfvGetById(),
    ArchiveMeeting(),
    ArchiveCoachingExport(),
    ArchiveJFVExport(),
    ArchiveMeetingExport(),
    ArchiveJFVDownloadDoc(),
    ArchiveMeetingGetById(),

    // FSC - OWN JFV
    UploadOwnJFV(),
    JfvDownloadDocForm(),

    // ADMIN
    LeaderJFVOverview(),
    AdminAgency(),
    AgentDetails(),
    AdminAgentSearch(),
    AdminAgentCreate(),
    AdminInfo(),
    AdminInfoValidate()
  ]);
  // OR
  // yield all([fork(FeatureSaga1)]);
}
