import React, { Suspense } from "react";
import { BrowserRouter, Redirect, Route, Router, Switch } from "react-router-dom";
import * as LazyComponent from "@utils/LazyLoaded";
import Loader from "@components/Loader/Loader";
import history from "./History";
import PrivateRoute from "@utils/PrivateRoute";

const Routes = () => {
  return (
    <BrowserRouter>
      <Suspense fallback={<Loader />}>
        <Router history={history}>
          <Switch>
            <Route component={LazyComponent.LoginFailed} path="/error/err=:errorStatus" exact />
            <Route component={LazyComponent.Validate} path={"/validate"} />
            <Route component={LazyComponent.ValidateIsmart} path={"/ssoRedirect"} />
            {/* FSC / T&C / COACHING MODULE */}
            {/* DINAMIC PAGE - 1 */}
            <PrivateRoute
              component={LazyComponent.CoachingElectronicForm}
              path="/fsc/t&c/create-coaching"
              exact
            />
            {/* DINAMIC PAGE - 2 */}
            <PrivateRoute
              component={LazyComponent.CoachingSubmitted}
              path="/fsc/t&c/coaching-submitted"
              exact
            />
            {/* DINAMIC PAGE - 3 */}
            <PrivateRoute
              component={LazyComponent.CoachingDeleted}
              path="/fsc/t&c/coaching-deleted"
              exact
            />
            {/* DINAMIC PAGE - 4 */}
            <PrivateRoute
              component={LazyComponent.CoachingSaved}
              path="/fsc/t&c/coaching-saved"
              exact
            />
            <PrivateRoute
              component={LazyComponent.CoachingAcknowledgement}
              path="/fsc/t&c/coaching-acknowledgement"
              exact
            />
            {/* STATIC PAGE - 1 */}
            <PrivateRoute
              component={LazyComponent.CoachingSuccess}
              path="/fsc/t&c/coaching-success/is-submitted=:submitted"
              exact
            />
            {/* STATIC PAGE - 2 */}
            <PrivateRoute
              component={LazyComponent.CoachingSuccess}
              path="/fsc/t&c/coaching-success/is-saved=:saved/:id?"
              exact
            />
            {/* STATIC PAGE - 3 */}
            <PrivateRoute
              component={LazyComponent.CoachingSuccess}
              path="/fsc/t&c/coaching-success/is-deleted=:deleted"
              exact
            />
            <PrivateRoute
              component={LazyComponent.CoachingSuccess}
              path="/fsc/t&c/coaching-acknowledge/is-acknowledge=:acknowledged"
              exact
            />

            {/* FSC / T&C / JOINT FIELD VISIT MODULE */}
            {/* DINAMIC PAGE - 5 */}
            <PrivateRoute
              component={LazyComponent.JFVElectronicForm}
              path="/fsc/t&c/create-jfv"
              exact
            />
            {/* DINAMIC PAGE - 6 */}
            <PrivateRoute
              component={LazyComponent.JFVSubmitted}
              path="/fsc/t&c/jfv-submitted"
              exact
            />
            <PrivateRoute
              component={LazyComponent.JFVAcknowledgement}
              path="/fsc/t&c/jfv-acknowledgement"
              exact
            />
            {/* DINAMIC PAGE - 7 */}
            <PrivateRoute component={LazyComponent.JFVDeleted} path="/fsc/t&c/jfv-deleted" exact />
            {/* DINAMIC PAGE - 8 */}
            <PrivateRoute component={LazyComponent.JFVSaved} path="/fsc/t&c/jfv-saved" exact />
            {/* STATIC PAGE - 4 */}
            <PrivateRoute
              component={LazyComponent.JFVSuccess}
              path="/fsc/t&c/jfv-success/is-submitted=:submitted"
              exact
            />
            {/* STATIC PAGE - 5 */}
            <PrivateRoute
              component={LazyComponent.JFVSuccess}
              path="/fsc/t&c/jfv-success/is-saved=:saved/:id?"
              exact
            />
            {/* STATIC PAGE - 6 */}
            <PrivateRoute
              component={LazyComponent.JFVSuccess}
              path="/fsc/t&c/jfv-success/is-deleted=:deleted"
              exact
            />

            {/* FSC / T&C / MEETING MODULE */}
            {/* DINAMIC PAGE - 9 */}
            <PrivateRoute
              component={LazyComponent.MeetingElectronicForm}
              path="/fsc/t&c/create-meeting"
              exact
            />
            {/* DINAMIC PAGE - 10 */}
            <PrivateRoute
              component={LazyComponent.MeetingSubmitted}
              path="/fsc/t&c/meeting-submitted"
              exact
            />
            {/* DINAMIC PAGE - 11 */}
            <PrivateRoute
              component={LazyComponent.MeetingSaved}
              path="/fsc/t&c/meeting-saved"
              exact
            />
            {/* DINAMIC PAGE - 12 */}
            <PrivateRoute
              component={LazyComponent.MeetingDeleted}
              path="/fsc/t&c/meeting-deleted"
              exact
            />
            {/* STATIC PAGE - 7 */}
            <PrivateRoute
              component={LazyComponent.MeetingSuccess}
              path="/fsc/t&c/meeting-success/is-submitted=:submitted"
              exact
            />
            {/* STATIC PAGE - 8 */}
            <PrivateRoute
              component={LazyComponent.MeetingSuccess}
              path="/fsc/t&c/meeting-success/is-saved=:saved/:id?"
              exact
            />
            {/* STATIC PAGE - 9 */}
            <PrivateRoute
              component={LazyComponent.MeetingSuccess}
              path="/fsc/t&c/meeting-success/is-deleted=:deleted"
              exact
            />

            {/* FSC / OVERVIEW / RECORD MONITORING */}
            {/* DINAMIC PAGE - 13 */}
            <PrivateRoute
              component={LazyComponent.RecordMonitoring}
              path="/fsc/overview/record-monitoring"
              exact
            />

            {/* FSC / OVERVIEW / LEADER OVERVIEW */}
            {/* DINAMIC PAGE - 14 */}
            <PrivateRoute
              component={LazyComponent.LeaderOverviewMenus}
              path="/fsc/overview/leader-overview"
              exact
            />
            {/* DINAMIC PAGE - 15 */}
            <PrivateRoute
              component={LazyComponent.LeaderCoachingOverview}
              path="/fsc/overview/coaching"
              exact
            />
            {/* DINAMIC PAGE - 16 */}
            <PrivateRoute
              component={LazyComponent.LeaderMeetingOverview}
              path="/fsc/overview/meeting"
              exact
            />
            {/* DINAMIC PAGE - 17 */}
            <PrivateRoute
              component={LazyComponent.LeaderJFVOverview}
              path="/fsc/overview/joint-field-visit"
              exact
            />

            {/* FSC / OVERVIEW / OVERVIEW DETAILED */}
            {/* DINAMIC PAGE - 18 */}
            <PrivateRoute
              component={LazyComponent.CoachingOverviewDetailed}
              path="/fsc/overview/coaching-detailed"
              exact
            />
            {/* DINAMIC PAGE - 19 */}
            <PrivateRoute
              component={LazyComponent.MeetingOverviewDetailed}
              path="/fsc/overview/meeting-detailed"
              exact
            />

            {/* FSC / OVERVIEW / ELECTRONIC ACKNOWLEDGEMENT */}
            {/* DINAMIC PAGE - 20 */}
            <PrivateRoute
              component={LazyComponent.Eacknowledgement}
              path="/fsc/overview/e-acknowledgement"
              exact
            />
            <PrivateRoute
              component={LazyComponent.RecordAcknowledgement}
              path="/fsc/overview/record-acknowledgement"
              exact
            />
            {/* STATIC PAGE - 10 */}
            <PrivateRoute
              component={LazyComponent.SubmitAssignmentSuccess}
              path="/fsc/e-acknoledgement/success"
              exact
            />

            {/* FSC / ADDITIONAL / ASSIGNMENT */}
            {/* DINAMIC PAGE - 21 */}
            <PrivateRoute component={LazyComponent.Assignment} path="/fsc/assignment" exact />
            {/* DINAMIC PAGE - 22 */}
            <PrivateRoute
              component={LazyComponent.AssignmentHistory}
              path="/fsc/assignment-history"
              exact
            />
            {/* STATIC PAGE - 11 */}
            <PrivateRoute
              component={LazyComponent.AssignmentSuccess}
              path="/fsc/assignment/success"
              exact
            />

            {/* FSC / ARCHIVE  */}
            {/* DINAMIC PAGE - 23 */}
            <PrivateRoute
              component={LazyComponent.ArchiveCoaching}
              path="/fsc/archive/coaching"
              exact
            />
            {/* STATIC PAGE - 12 */}
            <PrivateRoute
              component={LazyComponent.ArchiveCoachingDetailed}
              path="/fsc/archive/coaching-detailed"
              exact
            />
            {/* DINAMIC PAGE - 24 */}
            <PrivateRoute component={LazyComponent.ArchiveJFV} path="/fsc/archive/jfv" exact />
            {/* STATIC PAGE - 13 */}
            <PrivateRoute
              component={LazyComponent.ArchiveJfvDetailed}
              path="/fsc/archive/jfv-detailed"
              exact
            />
            {/* DINAMIC PAGE - 25 */}
            <PrivateRoute
              component={LazyComponent.ArchiveMeeting}
              path="/fsc/archive/meeting"
              exact
            />

            {/* STATIC PAGE - 14 */}
            <PrivateRoute
              component={LazyComponent.ArchiveMeetingDetailed}
              path="/fsc/archive/meeting-detailed"
              exact
            />

            {/* STAFF VIEW / ADMIN VIEW */}
            <PrivateRoute component={LazyComponent.StaffViewLandingPage} path="/admin" exact />
            <PrivateRoute
              component={LazyComponent.LeaderCoachingOverview}
              path="/staff-view/coaching-overview"
              exact
            />
            <PrivateRoute
              component={LazyComponent.LeaderJFVOverview}
              path="/staff-view/joint-field-visit"
              exact
            />
            <PrivateRoute
              component={LazyComponent.LeaderMeetingOverview}
              path="/staff-view/monthly-meeting"
              exact
            />

            {/* TNC  */}
            <PrivateRoute component={LazyComponent.AdminPortal} path="/admin-portal" exact />
            <PrivateRoute
              component={LazyComponent.PortalMonitoring}
              path="/admin-portal/portal-monitoring"
              exact
            />
            <PrivateRoute
              component={LazyComponent.BCSUpload}
              path="/admin-portal/bsc-upload"
              exact
            />
            <PrivateRoute
              component={LazyComponent.BSCGradeSheet}
              path="/admin-portal/bsc-grade-sheet/agencyCode=:agencyCode&month=:month&year=:year"
              exact
            />

            {/* STATIC PAGE - 15 */}
            <Route component={LazyComponent.Logout} path="/logout" exact />

            {/* DINAMIC PAGE - 26 */}
            <PrivateRoute component={LazyComponent.Home} path="/" exact />

            <Route component={LazyComponent.Unauthorized} path="/unauthorized" exact />

            {/* TEMPORARY */}
            {/* <Route component={LazyComponent.Login} path="/login" exact /> */}

            <Redirect from="**" to={"/unauthorized"} exact />
          </Switch>
        </Router>
      </Suspense>
    </BrowserRouter>
  );
};

export default Routes;
