import { loader } from "@redux-state/Loader/LoaderAction";
import axios from "axios";
import store from "@store/index";

import Auth from "@utils/Auth";
import { BASE_URL_API } from "@utils/Constants";

const exceptionApiUrlforRT = (config) => {
  if (!config) return null;
  const arr = [config.url.includes("/auth/validatetoken"),config.url.includes("/auth/generateToken")];
  return arr.includes(true);
};

export const isHandlerEnabled = (config = {}) => {
  return config.hasOwnProperty("handlerEnabled") && !config.handlerEnabled ? false : true;
};

export const requestHandler = async (config) => {
  if (isHandlerEnabled(config)) {
    // Modify request here
    store.dispatch(loader(true));
  }
  const auth = Auth.isValidate();
  if (auth) {
    config.headers["x-ior-auth"] = auth; // for Node.js Express back-end
  } else if (!exceptionApiUrlforRT(config)) {
    try {
      const rt = Auth.getRefreshToken();
      const resRT = await axios.get(`${BASE_URL_API}/auth/refreshtoken`, {
        headers: { rt }
      });
      const payload = resRT.data.data;
      Auth.storeUserInfoToCookie(payload);
      config.headers["x-ior-auth"] = payload.access.token;
    } catch (error) {
      const { message } = error;
      throw new Error(message);
    }
  }
  return config;
};

export const successHandler = (response) => {
  if (isHandlerEnabled(response)) {
    // Hanlde Response
    store.dispatch(loader(false));
  }
  return response;
};

export const errorHandler = (error) => {
  if (isHandlerEnabled(error.config)) {
    store.dispatch(loader(false));
    // You can decide what you need to do to handle errors.
    // here's example for unautherized user to log them out .
    if (error.response.status === 504) {
      return History.push(`/error/err=${error.response.status}`);
    }
  }
  return Promise.reject({ ...error });
};
