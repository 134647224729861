import { axiosInstance } from "@network/apis";

// Replace endpoint and change api name
export const apiGetValidateToken = async (data) => {
  try{
    const {
      codeVerifier,
      authCode
    } = data;
    //const result = await axiosInstance.post("/auth/generateToken",{authCode,codeVerifier},{
      //withCredentials: true
    //});
    const result = await axiosInstance.post("/auth/generateToken",{authCode,codeVerifier});
    console.log(result)
    return result;

  }catch(err){
    console.log(err.message)
  }

};
