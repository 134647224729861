import { call, put, takeLatest } from "redux-saga/effects";

import * as Actions from "./ValidateiSmartAction";
import { apiGetValidateToken } from "./ValidateiSmartApis";
import * as Types from "./ValidateiSmartTypes";

// Replace with your sagas
export function* getValidateiSmartToken(action) {
  try {
    console.log(action.data)
    const response = yield call(apiGetValidateToken, action.data);
    yield put(Actions.actionReceiveValidateToken(response.data));
  
  } catch (err) {
    console.log(err)
  }
}

export function* ValidateiSmartToken() {
  yield takeLatest(Types.POST_REQUEST_VALIDATE_TOKEN, getValidateiSmartToken);
}
