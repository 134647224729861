/* eslint-disable import/no-unresolved */

import adminAgency from "@redux-state/admin/AdminAgency/AdminAgencyReducer";
import adminAgentCreate from "@redux-state/admin/AgentCreate/AgentCreateReducer";
import adminAgentSearch from "@redux-state/admin/AgentSearch/AgentSearchReducer";
import adminInfo from "@redux-state/admin/AdminInfo/AdminInfoReducer";
import adminInfoValidate from "@redux-state/admin/AdminInfoForValidate/AdminInfoForValidateReducer";
import agency from "@redux-state/common/Agency/AgencyReducer";
import agentDetails from "@redux-state/admin/AgentDetailsTnC/AgentDetailsReducer";
import agentInfo from "@redux-state/common/AgentInfo/AgencyReducer";
import archiveCoaching from "@redux-state/Archive/ArchiveCoaching/ArchiveCoachingReducer";
import archiveCoachingGetById from "@redux-state/Archive/ArchiveCoaching/ArchiveCoachingGetById/ArchiveCoachingGetByIdReducers";
import archiveCoachingExport from "@redux-state/Archive/ArchiveCoaching/ArchiveCoachingExport/ArchiveCoachingExportReducer";
import archiveJFV from "@redux-state/Archive/ArchiveJFV/ArchiveJFVReducer";
import archiveJfvGetById from "@redux-state/Archive/ArchiveJFV/ArchiveJFVGetById/ArchiveJFVGetByIdReducers";
import archiveJFVDownloadDoc from "@redux-state/Archive/ArchiveJFV/ArchiveJFVDownloadDoc/ArchiveJFVDownloadDocReducer";
import archiveJFVExport from "@redux-state/Archive/ArchiveJFV/ArchiveJFVExport/ArchiveJFVExportReducer";
import archiveMeeting from "@redux-state/Archive/ArchiveMeeting/ArchiveMeetingReducer";
import archiveMeetingGetById from "@redux-state/Archive/ArchiveMeeting/ArchiveMeetingGetById/ArchiveMeetingGetByIdReducers";
import archiveMeetingExport from "@redux-state/Archive/ArchiveMeeting/ArchiveMeetingExport/ArchiveMeetingExportReducer";
import archiveAttachment from "@redux-state/JFV/JFVGetArchiveAttachment/JFVGetArchiveAttachmentReducer";
import assignmentList from "@redux-state/common/E-acknowledment/AssignmentListReducer";
import recordAcknowledgementList from "@redux-state/common/RecordAcknowledgement/RecordAcknowledgementListReducer";
import participant from "@redux-state/Meeting/SetParticipantSlice/SetParticipantSlice";
import auth from "@redux-state/Auth/AuthReducer";
import coachingDownloadDoc from "@redux-state/Coaching/CoachingDownloadDoc/CoachingDownloadDocReducer";
import coachingDownloadDocForm from "@redux-state/Coaching/CoachingDownloadDocForm/CoachingDownloadDocFormReducer";
import coachingGetById from "@redux-state/Coaching/CoachingGetById/CoachingGetByIdReducers";
import coachingSubmitted from "@redux-state/Coaching/CoachingSubmitted/CoachingSubmittedReducer";
import createCoaching from "@redux-state/Coaching/CreateCoaching/CreateCoachingReducer";
import createJFV from "@redux-state/JFV/JFVElectronicForm/CreateJFVReducer";
import createMeeting from "@redux-state/Meeting/MeetingElectronicForm/CreateMeetingReducer";
import downloadMeetingAbsent from "@redux-state/Meeting/MeetingAbsentDownload/MeetingAbsentDownloadReducer";
import getNotification from "@redux-state/common/Notification/NotificationReducer";
import getAssignmentFrom from "@redux-state/common/getAssignmentFrom/GetAssignmentFromReducer";
import getAssignmentTo from "@redux-state/common/getAssignmentTo/GetAssignmentToReducer";
import jfvDownloadDocForm from "@redux-state/JFV/JFVDownloadDocForm/JFVDownloadDocFormReducer";
import jfvGetById from "@redux-state/JFV/JFVGetById/JFVGetByIdReducers";
import jfvSubmitted from "@redux-state/JFV/JFVSubmitted/JFVSubmittedReducer";
import lang from "@redux-state/Lang/LangReducer";
import leaderAssignment from "@redux-state/common/LeaderAssignment/LeaderAssignmentReducer";
import leaderJFVOverview from "@redux-state/LeaderOverview/JFVOverview/JFVOverviewReducer";
import leaderList from "@redux-state/common/LeaderList/LeaderListReducer";
import loader from "@redux-state/Loader/LoaderReducer";
import manageCoaching from "@redux-state/Coaching/ManageCoaching/ManageCoachingReducer";
import manageJFV from "@redux-state/JFV/ManageJFV/ManageJFVReducer";
import manageMeeting from "@redux-state/Meeting/ManageMeeting/ManageMeetingReducer";
import meetingDownloadDoc from "@redux-state/Meeting/MeetingDownloadDoc/MeetingDownloadDocReducer";
import meetingDownloadDocForm from "@redux-state/Meeting/MeetingDownloadDocForm/MeetingDownloadDocFormReducer";
import meetingGetAbsentUpload from "@redux-state/Meeting/MeetingGetAbsentUpload/MeetingGetAbsentUploadReducers";
import meetingGetById from "@redux-state/Meeting/MeetingGetById/MeetingGetByIdReducers";
import meetingGetParticipantLists from "@redux-state/Meeting/MeetingGetParticipantLists/MeetingGetParticipantListReducers";
import meetingSubmitted from "@redux-state/Meeting/MeetingSubmitted/MeetingSubmittedReducer";
import menu from "@redux-state/Menu/MenuReducer";
import policyDetail from "@redux-state/common/PolicyDetails/PolicyDetailReducer";
import refreshToken from "@redux-state/RefreshAuth/RefreshReducer";
import snackbar from "@redux-state/Snackbar/SnackbarReducer";
import submitAssignment from "@redux-state/common/SubmitAssignment/SubmitAssignmentReducer";
import submitRecordAcknowledgement from "@redux-state/common/SubmitRecordAcknowledgement/SubmitRecordAcknowledgementReducer";
import updateCoaching from "@redux-state/Coaching/CoachingUpdate/CoachingUpdateReducer";
import deleteCoaching from "@redux-state/Coaching/CoachingDelete/CoachingDeleteReducer";
import deleteMeeting from "@redux-state/Meeting/MeetingDelete/MeetingDeleteReducer";
import deleteJFV from "@redux-state/JFV/JFVDelete/JFVDeleteReducer";
import updateJFV from "@redux-state/JFV/JFVUpdate/JFVUpdateReducer";
import updateMeeting from "@redux-state/Meeting/MeetingUpdate/MeetingUpdateReducer";
import uploadAbsentMeeting from "@redux-state/Meeting/MeetingAbsentUpload/MeetingAbsentUploadReducer";
import uploadCoaching from "@redux-state/Coaching/CoachingUpload/CoachingUploadReducer";
import uploadCoachingForm from "@redux-state/Coaching/CoachingUploadForm/CoachingUploadFormReducer";
import uploadMeeting from "@redux-state/Meeting/MeetingUpload/MeetingUploadReducer";
import uploadMeetingForm from "@redux-state/Meeting/MeetingUploadForm/MeetingUploadFormReducer";
import uploadOwnJFV from "@redux-state/JFV/OwnJFVUpload/OwnJFVUploadReducer";
import validate from "@redux-state/Validate/ValidateReducer";
import validateiSmart from "@redux-state/Validate_iSmart/ValidateiSmartReducer";
import validateTiming from "@redux-state/common/ValidateTiming/ValidateTimingReducer";
import validatePolicy from "@redux-state/common/ValidatePolicy/ValidatePolicyReducer";

import { combineReducers } from "redux";

const rootReducer = combineReducers({
  // AUTH
  auth,
  validate,
  validateiSmart,
  refreshToken,
  menu,

  // UTILS
  lang,
  loader,
  snackbar,

  // COMMON
  validateTiming,
  agency,
  agentInfo,
  leaderList,
  assignmentList,
  recordAcknowledgementList,
  policyDetail,
  leaderAssignment,
  submitAssignment,
  submitRecordAcknowledgement,
  getAssignmentTo,
  getAssignmentFrom,
  validatePolicy,
  archiveAttachment,

  // FSC
  // FSC - COACHING
  createCoaching,
  uploadCoaching,
  uploadCoachingForm,
  updateCoaching,
  deleteCoaching,
  manageCoaching,
  coachingSubmitted,
  coachingGetById,
  coachingDownloadDoc,
  coachingDownloadDocForm,

  // FSC - JOINT FIELD VISIT
  createJFV,
  updateJFV,
  manageJFV,
  jfvSubmitted,
  jfvGetById,
  jfvDownloadDocForm,
  deleteJFV,

  // FSC - MEETING
  createMeeting,
  participant,
  manageMeeting,
  meetingSubmitted,
  updateMeeting,
  uploadMeeting,
  uploadMeetingForm,
  uploadAbsentMeeting,
  meetingGetById,
  meetingGetAbsentUpload,
  downloadMeetingAbsent,
  meetingDownloadDoc,
  meetingDownloadDocForm,
  getNotification,
  meetingGetParticipantLists,
  deleteMeeting,

  // FSC - ARCHIVE
  archiveCoaching,
  archiveCoachingExport,
  archiveCoachingGetById,
  archiveJFV,
  archiveJfvGetById,
  archiveJFVExport,
  archiveJFVDownloadDoc,
  archiveMeeting,
  archiveMeetingExport,
  archiveMeetingGetById,

  // FCS - OWN JFV
  uploadOwnJFV,

  // ADMIN
  leaderJFVOverview,
  adminAgency,
  agentDetails,
  adminAgentSearch,
  adminAgentCreate,
  adminInfo,
  adminInfoValidate
});

export default rootReducer;
